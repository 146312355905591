<template>
  <div style='position: relative;min-height: 835px;'>
    <!--    <div style="background-color:#323233 ; border-radius: 10px;margin: 20px 15px 0px 15px;padding:0 20px;height: 30px;line-height: 30px" >-->
    <!--      <div @click="clickFn()">股票策略类型：-->
    <!--        <div style="display: inline-block">-->
    <!--          <span v-if="query.stock_strategy_type==''">请选择策略类型</span>-->
    <!--          <span v-else>{{query.stock_strategy_type}}</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <van-popup  v-model="showPicker" position="bottom">-->
    <!--        <van-picker :columns="columns" @change="onChange" confirm-button-text='确定' cancel-button-text='取消' :show-toolbar='true' :default-index="2"  @confirm="onConfirm"/>-->
    <!--      </van-popup>-->
    <!--    </div>-->

    <div class="page">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onListLoad"
          class="list"
      >
        <div style="padding: 20px">
          <div v-for="(item, index) in stock_list" :key="index"
               style="margin-bottom: 20px;border-left: 3px solid goldenrod;border-radius: 2%; padding-left: 10px">
            <div>
              <div>
                {{ item.stock_strategy_name }}交易计划
              </div>
              <div>
                <span>买入日期：{{ item.buy_time }}</span>
                <br><span>卖出日期：{{ item.sell_time }}</span>
                <div v-if="seen">仓位比列：{{ getFixed(item.pos_rate, 2) }} %</div>
                <div v-if="asset_id_seen">资金编号：{{ item.asset_number }} #</div>
              </div>
            </div>
            <!--      表格-->
            <table style="width: 70%;border-collapse:collapse;margin-top: 10px" border="1">
              <tr>
                <th>股票名称</th>
                <th>股票代码</th>
              </tr>
              <tr v-for="citem in item.stock_code.length" :key="citem" style="text-align: center">
                <td>{{ item.stock_name[citem - 1] }}</td>
                <td>{{ item.stock_code[citem - 1] }}</td>
              </tr>
            </table>
          </div>

        </div>
      </van-list>
    </div>
  </div>

</template>

<script>
import {getStockData} from "../../api/stock";
import InfoCommen from "../../mixins/InfoCommen";
import {Notify} from "vant";
// import { ref } from 'vue';

export default {
  name: "stock_information",
  components: {
    [Notify.Component.name]: Notify.Component,
  },
  mixins: [InfoCommen],
  data() {
    return {
      stock_list: [],
      showPicker: false,
      show: false,
      columns: ['选股', '事件', '轮动'],
      loading: false,
      p_loading: false,
      finished: false,
      seen: false,
      asset_id_seen: false,
      list: [],
      query: {
        wx_fw_open_id: 'obstN52G4DW3xEvcRi-_uSOE0bC4',
        stock_strategy_type: '',
        page_size: 10,
        page_num: 1
      },
    }
  },
  created() {
  },
  mounted() {
    // this.page_ = this.$router.params.
    // this.handleGetList()
  },
  methods: {
    //获取数据
    handleGetList() {
      this.p_loading = true;
      console.log(this.query)
      getStockData({...this.query}).then((res) => {
        if (res.code == 0) {
          console.log(res.data)
          let list = res.data.list.map(item => {
            return {
              stock_strategy_name: item.stock_strategy_name,
              buy_time: item.买入日期,
              sell_time: item.卖出日期,
              pos_rate: item.pos_rate * 100,
              stock_code: item.买入股票代码,
              stock_name: item.买入股票名称,
              asset_number: item.资金编号
            }
          })
          let tmp_stock_strategy_type = res.data.list[0].stock_strategy_type
          if (tmp_stock_strategy_type === "轮动") {
            this.seen = true
          }
          if (tmp_stock_strategy_type === "事件" && typeof list[0]['asset_number'] !== "undefined") {
            this.asset_id_seen = true
          }
          if (this.query.page_num === 1) {
            this.stock_list = list
          } else {
            this.stock_list = [...this.stock_list, ...list]
          }
          this.loading = false
          this.p_loading = false
          this.finished = this.stock_list.length >= res.data.total_size
        } else {
          Notify({type: 'danger', message: '获取股票数据失败'});
          this.finished = true
        }

      }).finally(() => {
        // eslint-disable-next-line no-debugger
        this.p_loading = false
        this.loading = false

      })
    },
    //刷新页面
    onListLoad() {
      if (this.p_loading) {
        return
      }
      this.query.page_num = this.query.page_num + 1
      this.handleGetList()
    },

    clickFn() {
      this.showPicker = true
    },
    onConfirm(value) {
      this.query.stock_strategy_type = value
      this.query.page_num = 1
      this.handleGetList()
      this.showPicker = false
    },
    onChange(picker, value, index) {
      console.log(picker, value, index)
      // this.query.stock_strategy_type = value
      // Toast(`当前值：${value}, 当前索引：${index}`)
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from)
    //http://mob.ocatfortune.com/stock_information?open_id=obstN52G4DW3xEvcRi-_uSOE0bC4
    if (to.path === '/stock_information') {
      next(vm => {
        vm.query.wx_fw_open_id = to.query.open_id
      })
    }
    next(vm => {
      vm.handleGetList()
    })
  },
}
</script>

<style scoped>

</style>